const Global = {
  /*************** Desarrollo *****************/
  //API REST de AZURE PRUEBAS2
   //apiRESTUrl: "https://pruebas2-node.azurewebsites.net/api",

  
  /*************** Produccion *****************/
  //API REST de AZURE
  apiRESTUrl : "https://backend-grobmx.azurewebsites.net/api",
 //API REST Maquina RODRIGO 
  //apiRESTUrl : "http://172.16.10.87:3001/api",

  google_maps_URL: "https://www.google.com/maps/dir/?api=1&&destination=",

  foto_nd: "https://storagegovi.blob.core.windows.net/fotos/img_nd.png",


  //Se actualiza en mantenimentos de datos y fotos, en formato MM-YYYY
  fotos_URL: "https://storagegovi.blob.core.windows.net/grob/12-2024/i_",


  //Se actualizarán sólo si hay nuevas fotos 360
  urlFotos360: "https://storagegovi.blob.core.windows.net/360/12-2021/",
  
  leyendaServicios: "Somos expertos en seguridad y confort",
  leyendaProductos: "Nuestra calidad es desarrollada en equipo original.",
  leyendaCatalogo:
    " Contamos con más de 16,000 códigos para más de 284,000 aplicaciones.",
  leyendaNosotros: "GRUPO GOVI",
  leyendaUbicaciones: "Una sucursal siempre cerca de ti.",
  leyendaContacto:
    "Puede enviarnos sus preguntas, comentarios ó sugerencias en el siguiente formulario, estamos a sus órdenes:",

  //ReCAPTCHA para localhost, 172.16.10.21 y GOVI.MX
  siteKeyClient: "6LcSSl8aAAAAALQtv0ZcZYAlRZw2gmJ12z1xcjZR",
};

export default Global;
